import beeple from './../../assets/img/beeple.png'

export default function AboutAndWhy({ Image }) {
  return (
    <section className="about-why">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="image" style={{marginLeft: '-80px'}}>
              <img loading="lazy" src={Image} alt="About &amp; Why Nexus" className="bounce img-fluid" />
              
            </div>
           
          </div>
          <div className="col-md-8 d-flex align-items-center">
            <div className="content">
              <h2>FREE NFT MINTING ON POLYGON</h2>
              <p><b>You can start minting your own NFTs</b><em>with NO GAS FEES</em>  by signing up right now as a creator or collector.</p>
              <p><b><em>You also designate any % you want to be paid on the resale of your art.</em></b></p>
              <div className="d-flex">
                <div className="image" style={{width: '40%'}}>
                  <img loading="lazy" src={beeple} alt="" className="img-fluid" />
                </div>
                <div style={{flex: '1', marginLeft: '20px'}}>
                  <p><b>The Nexus Platform exists to empower both creators & collectors, offering an open marketplace for NFT Art, music, games, films & immersive VR & AR experiences. We’re the best place to successfully market your body of work or invest in the creators you know & love.</b></p>
                  <p><b>But we’re not trying to convince you, we’re sure you’ve already heard the good news!</b></p>
                </div>
              </div>



              <h2 className="text-center mt-2">Beeple sells NFT for 69 million $</h2>

            </div>
          </div>
        </div>
      </div>
      <div className="section-bg-blue follow-us">
              <div className='btn-shadow'>
                <button className='btn follow hvr-bounce-in'><span>BUILT ON POLYGON / MATIC</span></button>
              </div>
            </div>
    </section>
  )
}
