import { useState, useEffect } from 'react';
import Logo from './../../assets/img/svg/logo.svg';
import { getAllProductscApi, BoostedProductsApi,getAllProductsSearchApi } from '../../container/Api/api';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Loader from '../loader/Loader';
import Swal from 'sweetalert2';
import { Pagination } from 'antd';
import { usdSymbol, ethSymbol } from '../../constant/constant'
import { connect } from 'react-redux';
import { EthPriceCalculation } from '../../utils/ethPriceFunction'
import { ethereumPriceToggler } from '../../store/actions/userAction'
import Countdown from 'react-countdown';
import VideoImageThumbnail from 'react-video-thumbnail-image';
import { ErrorHandler } from '../../utils/message';
 
const Completionist = () => <span>Completed!</span>;

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return <Completionist />;
  } else {
    return <span style={{ fontSize: '16px', fontWeight: 'bold', marginLeft: '5px', letterSpacing: '1px' }}>
      {days}:{hours}:{minutes}:{seconds}
    </span>
  }
}

// const $ = window.jQuery;
const Searched = (props) => {

  const [state, setState] = useState({
    isApiHit: false,
    query: '',
    data: [],
    min: '',
    max: '',
    totalRecord: 0,
    currentTab: props.location.pathname,
    users:null
  })

  const [currentPage, setCurrentPage] = useState(1)
  const [loading, setLoading] = useState(true)
  const [list, setList] = useState(false)
  const [grid, setGrid] = useState(true)
  const [ethereum, setEthereum] = useState('')
  const [boostedProducts, setBoostedProducts] = useState('')
  const [stringQuery, setStringQuery] = useState(0)
  const [selectedRadioValue, setSelectedRadioValue] = useState('')
  const [checkedValues, setCheckedValues] = useState([])
  const [sortOption] = useState([
    { value: 'newest', name: 'Newest' },
    { value: 'oldest', name: 'Oldest' },
    { value: 'lower', name: 'Price - Low to high' },
    { value: 'higher', name: 'Price - High to low' },
    { value: 'only_auction', name: 'Only Auction' },
    { value: 'resellable', name: 'Resellable' },
    { value: 'transfer_copyright', name: 'Copyright transferred' },
    { value: 'nsfw', name: 'NSFW' }
  ])

  const history = useHistory();
  const location =useLocation();

  const CalculatePrice = async () => {
    let value = await EthPriceCalculation()
    setEthereum(value)
  }

  let pathName = props.location.pathname;
  let routeName = pathName.split('/');
  let currentTab = routeName[2];

  const listView = () => {
    setList(true)
    setGrid(false)
  }

  const gridView = () => {
    setList(false)
    setGrid(true)
  }

  async function fetchMyAPI(isUrlChange) {
    let search=new URLSearchParams(location.search).get('search')
    let query = '';
    // if (isUrlChange) {
    //   query = currentTab
    // } else {
    //   query = state.query ? state.query : currentTab
    // }
    query += `offset=${isUrlChange ? 0 : currentPage - 1}`
    query += `&search=${search}&user=1`
    let res = await getAllProductsSearchApi(query)
    if (res && res.status === 200) {
      let data = res.data.data;
      let users=res.data.users
      setState({
        ...state,
        data: data,
        totalRecord: res.data && res.data.total_records ? res.data.total_records : 0,
        isApiHit: true,
        query: query,
        currentTab,
        users:users
      })
      setBoostedProducts(res.data.boosted_products)
      setLoading(false)
    } else if (res && res.data.status === 0) {
      setState({
        ...state,
        data: [],
        totalRecord: 0,
        isApiHit: true,
        query: '',
        currentTab: '',
        users:null
      })
      setLoading(false)
    }
  }

  useEffect(() => {
    console.log("api hit 2")
    let pathName = props.location.pathname;
    let routeName = pathName.split('/');
    let currentTab = routeName[2];
    CalculatePrice()
    if (!state.isApiHit || currentTab !== state.currentTab || location.search) {
      setLoading(true)
      let isUrlChange = currentTab !== state.currentTab ? true : false
      console.log("api hit 1")
      fetchMyAPI(isUrlChange)
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isApiHit, props.location,props.search])

  const applyFilter = (event, type, string = stringQuery) => {
    event.preventDefault()
    let query = '';
    if (type === 'currencyRange') {
      if (!state.min) {
        Swal.fire('Oops...', 'Pleas enter the min value!', 'error')
      } else if (!state.max) {
        Swal.fire('Oops...', 'Pleas enter the max value!', 'error')
      } else {
        query = `${state.currentTab}&min=${state.min}&max=${state.max}`;
      }
    } else {
      if (string === 0) {
        query = `${state.currentTab}&${type}=1`;
      } else {
        query = `${state.currentTab}&${string}`;
      }
    }
    setState({
      ...state,
      query,
      isApiHit: false
    })
  }

  const handleUpdateCurrency = (state) => {
    props.dispatch(ethereumPriceToggler(!state))
  }

  const changeHandler = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  const handleChangeCount = (value) => {
    setCurrentPage(value)
    setLoading(true)
    setState({
      ...state,
      isApiHit: false
    })
  }

  let boostedProductsApiCall = async (ad, slug, id) => {
    let res = await BoostedProductsApi(ad)
    setLoading(true)
    try {
      if (res) {
        setLoading(false)
        history.push(`/product-detail/${slug}/${id}`)
      }
    } catch (err) {
      ErrorHandler(err)
    }
  }

  let handleChange = (e) => {
    let target = e.target;
    let value = target.type === 'checkbox' && target.checked ? target.value : '';
    let newArr = [...checkedValues];
    if (target.checked) {
      if (!newArr.includes(value + '=1')) {
        newArr.push(value + '=1');
        setCheckedValues(newArr)
      }
    } else {
      let filterOne = newArr.indexOf(e.target.value + '=1');
      newArr.splice(filterOne, 1)
      setCheckedValues(newArr)
    }
  }

  let handleSort = (e) => {
    let target = e.target;
    let value = target.type === 'radio' && target.checked ? target.value : '';
    if (target.checked) {
      setSelectedRadioValue('order_by=' + value)
    }
  }

  useEffect(() => {
    let str = checkedValues && checkedValues.toString()
    str += `&${selectedRadioValue}`
    if (str) {
      let min = state.min > 0 ? `&min=${state.min}` : '';
      let max = state.max > 0 ? `&max=${state.max}` : '';
      let stringif = str.includes(',') ? str.replaceAll(',', '&') : str + min + max;
      setStringQuery(stringif)
    }
  }, [checkedValues, state.min, state.max, selectedRadioValue])


  let curentCategory = state.currentTab === 'music' ? "Music" : state.currentTab === 'art' ? "Art" : state.currentTab === "film" ? "Film" : '';
  if (loading) {
    return <Loader />
  } return (
    <>
      <div className="main-wrapper category">
        <div className="page-title-section">
          <h1>{curentCategory}</h1>
          <p>{ }</p>
        </div>

        <div className="main-padding">
          <div className="mt-5 breadcrumb">
            <div className="breadcrumb-item"><Link to='/'>Home</Link></div>
            <div className="breadcrumb-item active" aria-current="page">{curentCategory}</div>
          </div>
            
          <div className="row">
            

            <div className="col-lg-12">
              <div className="form-check form-switch">
                <b>{usdSymbol}</b>
                <label className="switch">
                  <input type="checkbox" value={props.ethPriceState} onChange={() => handleUpdateCurrency(props.ethPriceState)} />
                  <span className="slider round"></span>
                </label>
                <b>{ethSymbol}</b>
              </div>
              <div className="collection-main">
                <div className="collection-filters">
                  <div className="items-show">
                    <span>Showing {state.data.length}/{state.totalRecord}</span>
                  </div>
                  <div className="items-view">

                    <button className={list ? "list active" : 'list'} onClick={listView}><i className="fa fa-bars" /></button>
                    <button className={grid ? "grid active" : 'grid'} onClick={gridView}><i className="fa fa-th-large" /></button>
                  </div>
                </div>
                <h2 className="mb-3">Users</h2>
                <div className={list ? "collection-items product-list" : "collection-items product-grid"} >
                    {/* Boosted Products */}
                    {state.users && Object.keys(state.users).length > 0 ?
                      <>
                        {state.users.map((item, index) => {
                          return <div className="product-item user" key={index}>
                            <div className="product-wrap">
                              <div className="image">
                                {/* <span onClick={() => boostedProductsApiCall(item.adId, item.slug, item.id)} > */}
                                  <img src={item.profile_image} alt={item.id} className="img-fluid" />
                                  {/* </span> */}
                              </div>
                              <div className="product-info">
                                <Link to={{ pathname: `/user/${item.username}` }}><h6 className="title">{item.username} </h6></Link>
                                <p>{item.sub_title} </p>
                                <div className="seller-info">
                                  <div className="item">
                                    <span className="title">Seller: </span>
                                    <Link to={`/user/${item.username}`} className="value">{item.username}</Link>
                                  </div>

                                  {/* {item.priceType !== 0 ? <div className="item">
                                    <span className="title">Auction Time:</span>
                                    <span className="value" id="timer">
                                      {item.auctionTime !== "Expired" ?
                                        <Countdown
                                          date={new Date(Number(item.auctionTime) * 1000).getTime()}
                                          renderer={renderer}
                                        />
                                        : "Expired"}
                                    </span>
                                  </div> : ""} */}

                                </div>
                              </div>
                             
{/*                              
                              <div className="actions">
                                <div className="price-wrapper">
                                  {
                                    !props.ethPriceState && item.priceType === "0" ?
                                      <span className="price priceUsd">{usdSymbol}{item.priceUsd}</span>
                                      :
                                      !props.ethPriceState && item.priceType === "1" ?
                                        <span className="price bidprice">Bid Price: {usdSymbol}{item.bidPriceUsd}</span>
                                        :
                                        !props.ethPriceState && item.priceType === "2" ?
                                          <>
                                            <span className="price priceUsd">{usdSymbol}{item.priceUsd}</span>
                                            <span className="price bidprice">Bid Price: {usdSymbol}{item.bidPriceUsd}</span>
                                          </>

                                          : props.ethPriceState && item.priceType === "0" ?
                                            <span className="price priceUsd">{ethSymbol}{(item.priceUsd / ethereum).toFixed(4)}</span>

                                            : props.ethPriceState && item.priceType === "1" ?
                                              <span className="price bidprice">Bid Price: {ethSymbol}{(item.bidPriceUsd / ethereum).toFixed(4)}</span>

                                              : props.ethPriceState && item.priceType === "2" ?
                                                <>
                                                  <span className="price priceUsd">{ethSymbol}{(item.priceUsd / ethereum).toFixed(4)}</span>
                                                  <span className="price bidprice">Bid Price: {ethSymbol}{(item.bidPriceUsd / ethereum).toFixed(4)}</span>
                                                </> : ''
                                  }
                                </div>
                                <div className="boosted">
                                  <button className='btn btn-link' onClick={() => boostedProductsApiCall(item.adId, item.slug, item.id)}><span className="icon">
                                  </span>Boosted</button>
                                </div>

                              
                              </div>
                             */}
                            </div>
                          </div>
                        })}
                      </> : <div className='m-auto'>
                        <div className="no-content-class">
                          <h4>No User Found!</h4>
                        </div>
                      </div>}

                  </div>
                  <h2 className="mb-3">Products</h2>
                <div className={list ? "collection-items product-list" : "collection-items product-grid"} >
                  {/* Boosted Products */}
                  {boostedProducts && Object.keys(boostedProducts).length > 0 ?
                    <>
                      {boostedProducts.map((item, index) => {
                        return <div className="product-item" key={index}>
                          <div className="product-wrap">
                            <div className="image">
                              <span onClick={() => boostedProductsApiCall(item.adId, item.slug, item.id)} >
                                <img src={item.ipfsImageHash} alt={item.title} className="img-fluid" /></span>
                            </div>
                            <div className="product-info">
                              <Link to={{ pathname: `/product-detail/${item.slug}/${item.id}` }}><h6 className="title">{item.title} </h6></Link>
                              <p>{item.sub_title} </p>
                              <div className="seller-info">
                                <div className="item">
                                  <span className="title">Seller: </span>
                                  <Link to={`/user/${item.seller}`} className="value">{item.seller}</Link>
                                </div>
                                {item.priceType !== 0 ? <div className="item">
                                  <span className="title">Auction Time:</span>
                                  <span className="value" id="timer">
                                    {item.auctionTime !== "Expired" ?
                                      <Countdown
                                        date={new Date(Number(item.auctionTime) * 1000).getTime()}
                                        renderer={renderer}
                                      />
                                      : "Expired"}
                                  </span>
                                </div> : ""}

                              </div>
                            </div>
                            <div className="actions">
                              <div className="price-wrapper">
                                {
                                  !props.ethPriceState && item.priceType === "0" ?
                                    <span className="price priceUsd">{usdSymbol}{item.priceUsd}</span>
                                    :
                                    !props.ethPriceState && item.priceType === "1" ?
                                      <span className="price bidprice">Bid Price: {usdSymbol}{item.bidPriceUsd}</span>
                                      :
                                      !props.ethPriceState && item.priceType === "2" ?
                                        <>
                                          <span className="price priceUsd">{usdSymbol}{item.priceUsd}</span>
                                          <span className="price bidprice">Bid Price: {usdSymbol}{item.bidPriceUsd}</span>
                                        </>

                                        : props.ethPriceState && item.priceType === "0" ?
                                          <span className="price priceUsd">{ethSymbol}{(item.priceUsd / ethereum).toFixed(4)}</span>

                                          : props.ethPriceState && item.priceType === "1" ?
                                            <span className="price bidprice">Bid Price: {ethSymbol}{(item.bidPriceUsd / ethereum).toFixed(4)}</span>

                                            : props.ethPriceState && item.priceType === "2" ?
                                              <>
                                                <span className="price priceUsd">{ethSymbol}{(item.priceUsd / ethereum).toFixed(4)}</span>
                                                <span className="price bidprice">Bid Price: {ethSymbol}{(item.bidPriceUsd / ethereum).toFixed(4)}</span>
                                              </> : ''
                                }
                              </div>
                              <div className="boosted">
                                <button className='btn btn-link' onClick={() => boostedProductsApiCall(item.adId, item.slug, item.id)}><span className="icon">
                                </span>Boosted</button>
                              </div>

                            </div>
                          </div>
                        </div>
                      })}
                    </> : ''}

                  {state.data && Object.keys(state.data).length > 0 ?
                    state.data.map((item, index) => {
                      let mediaType = JSON.parse(item.productMedia);
                      return <div className="product-item" key={index} >
                        <div className="product-wrap">
                          <div className="image">
                            <Link to={{ pathname: `/product-detail/${item.slug}/${item.id}` }}>
                              {mediaType.fileType.includes('image') ? <img src={item.ipfsImageHash} alt={item.title} className="img-fluid" /> : <VideoImageThumbnail snapshotAtTime={0}
                                videoUrl={item.ipfsImageHash}

                                className="img-fluid"
                                alt={item.title}
                              />
                              }</Link>
                          </div>
                          <div className="product-info">
                            <Link to={{ pathname: `/product-detail/${item.slug}/${item.id}` }}><h6 className="title">{item.title} </h6></Link>
                            <p>{item.sub_title} </p>
                            <div className="seller-info">
                              <div className="item">
                                <span className="title">Seller: </span>
                                <Link to={`/user/${item.seller}`} className="value">{item.seller}</Link>
                              </div>
                              {item.priceType !== 0 ? <div className="item">
                                <span className="title">Auction Time:</span>
                                <span className="value" id="timer">
                                  {item.auctionTime !== "Expired" ?
                                    <Countdown
                                      date={new Date(Number(item.auctionTime) * 1000).getTime()}
                                      renderer={renderer}
                                      zeroPadTime={2}
                                    />
                                    : "Expired"}
                                </span>
                              </div> : ""}

                            </div>
                          </div>
                          <div className="actions">
                            <div className="price-wrapper">
                              {
                                !props.ethPriceState && item.priceType === "0" ?
                                  <span className="price priceUsd">{usdSymbol}{item.priceUsd}</span>
                                  :
                                  !props.ethPriceState && item.priceType === "1" ?
                                    <span className="price bidprice">Bid Price: {usdSymbol}{item.bidPriceUsd}</span>
                                    :
                                    !props.ethPriceState && item.priceType === "2" ?
                                      <>
                                        <span className="price priceUsd">{usdSymbol}{item.priceUsd}</span>
                                        <span className="price bidprice">Bid Price: {usdSymbol}{item.bidPriceUsd}</span>
                                      </>

                                      : props.ethPriceState && item.priceType === "0" ?
                                        <span className="price priceUsd">{ethSymbol}{(item.priceUsd / ethereum).toFixed(4)}</span>

                                        : props.ethPriceState && item.priceType === "1" ?
                                          <span className="price bidprice">Bid Price: {ethSymbol}{(item.bidPriceUsd / ethereum).toFixed(4)}</span>

                                          : props.ethPriceState && item.priceType === "2" ?
                                            <>
                                              <span className="price priceUsd">{ethSymbol}{(item.priceUsd / ethereum).toFixed(4)}</span>
                                              <span className="price bidprice">Bid Price: {ethSymbol}{(item.bidPriceUsd / ethereum).toFixed(4)}</span>
                                            </> : ''
                              }
                            </div>
                            <Link to={{ pathname: `/product-detail/${item.slug}/${item.id}` }} className="btn-default hvr-bounce-in"><span className="icon">
                              <img className="img-fluid" src={Logo} alt="Nexus Logo" />
                            </span>View Details</Link>
                          </div>
                        </div>
                      </div>
                    }) : <div className='m-auto'>
                      <div className="no-content-class">
                        <h4>No Item Available</h4>
                      </div>
                    </div>
                  }
                </div>
                {state.totalRecord > 10 ? <Pagination
                  current={state.currentPage}
                  defaultCurrent={state.currentPage}
                  total={state.totalRecord}
                  onChange={handleChangeCount}
                /> : ''}
              </div>
            </div>


          </div>
        </div>
      </div>
    </>
  )
}
const mapStateToProps = state => {
  return {
    ethPriceState: state.userReducer.ethPriceState,
  }
}

export default connect(mapStateToProps)(Searched);