import { useState, useEffect } from "react";
import { SubscibeApi } from '../../container/Api/api';
import { Message, messageFormater } from '../../utils/message';
import SmallLoader from '../loader/SmallLoader'
import { connect } from 'react-redux'
import { selectedUserType } from './../../store/actions/userAction'
import FlipClock from 'x-react-flipclock'
const $ = window.jQuery

function CreatorInvestor(props) {

  const [time, setTime] = useState(0)
  const [token, setToken] = useState(localStorage.getItem('token'))
  // const [day, setDay] = useState(0)
  // const [hours, setHours] = useState(0)
  // const [minutes, setMinutes] = useState(0)
  // const [seconds, setSeconds] = useState(0)
  const [state, setState] = useState({
    email: '',
    name: '',
    isSubmitted: false,
    errors: '',
    loading: false,
    success: false
  })

  useEffect(() => {
    let d = new Date(props.launch_time * 1000);
    let t = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate() + ' ' + d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds()
    setTime(t)
  }, [props.launch_time])

  // useEffect(() => {
  //   let countDownDate = new Date(time * 1000).getTime();
  //   let x = setInterval(function () {
  //     let now = new Date().getTime();
  //     let distance = countDownDate - now;
  //     setDay(Math.floor(distance / (1000 * 60 * 60 * 24)))
  //     setHours(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)))
  //     setMinutes(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)))
  //     setSeconds(Math.floor((distance % (1000 * 60)) / 1000))
  //     if (distance < 0) {
  //       clearInterval(x);
  //       setDay(0)
  //       setHours(0)
  //       setMinutes(0)
  //       setSeconds(0)
  //     }
  //   }, 1000)
  // }, [time])

  const openSignUpTab = (param, value) => {
    $(`#${param}`).tab('show');
    props.dispatch(selectedUserType(value))
  }

  const openSubscribeModel = () => {
    $(`#subscribeModel`).modal('show');
  }

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
      errors: ''
    })
  }

  const closeSubscribeModel = () => {
    setState({
      email: '',
      name: '',
      isSubmitted: false,
      errors: '',
      loading: false,
      success: false
    })
  }

  const subscribeHandler = async () => {
    const { name, email } = state;
    if (!email) {
      Message('error', 'Error', 'Please enter email')
    } else {
      let body = {};
      if (name) {
        body['name'] = name
      } if (email) {
        body['email'] = email
      }
      setState({
        ...state,
        loading: true
      })
      let res = await SubscibeApi(body)
      if (res && res.data.status && res.data.status === 1) {
        Message('success', 'Success', 'Successfully Subscibe')
        $(`#subscribeModel`).modal('hide');
        setState({
          ...state,
          email: '',
          name: '',
          isSubmitted: false,
          errors: '',
          loading: false,
          success: false
        })
      } else if (res && res.data.status && res.data.status === 403) {
        setState({
          ...state,
          email: '',
          name: '',
          isSubmitted: false,
          errors: '',
          loading: false,
          success: false
        })
        Message('error', 'Error', 'Email is already exists')
      } else {
        if (res && res.data && res.data.status === 0) {
          let message = '';
          if (typeof res.data.message === 'string') {
            message = res.data.message
          } else if (typeof res.data.message === 'object') {
            message = res.data.message.email
          }
          let errorMessage = messageFormater(message)
          if (errorMessage) {
            Message('error', 'Error', errorMessage)
          }
        }
        setState({
          ...state,
          email: '',
          name: '',
          isSubmitted: false,
          errors: '',
          loading: false,
          success: false
        })
      }
    }
  }

  useEffect(() => {
    let tok = Boolean(localStorage.getItem('token'))
    if (tok) {
      setToken(localStorage.getItem('token'))
    } else {
      setToken(props.token)
    }
  }, [props.token])

  return (
    <section className="creater-investor">
      <div className="container">
        <div className="row">
          <div className="col-md-7 d-flex align-items-center justify-content-center">
            <div className="content">
              <h2>Creator or Investor ?</h2>
              {/* <p>Nulla in euismod magna eauris et tincidunt enim xenean lobortis</p> */}
              <div className="cards">
                <div>
                  {!token ? <div className="boxes-wrapper"><div data-toggle='modal' data-target='.bd-example-modal-lg' onClick={() => openSignUpTab('v-pills-signup-tab', 2)} className="boxes creater">
                    <span className="h3" >Creators </span>
                    <span className="h5">GET IN THE GAME</span>
                  </div> </div> : <div className="boxes-wrapper"><div data-toggle='modal' className="boxes creater">
                    <span className="h3" >Creators </span>
                  </div></div>}
                  <div className="points">
                    <ul>
                      <li>Free Minting for New Creators</li>
                      <li>Join The NFT Revolution</li>
                    </ul>
                  </div>
                </div>
                <div>
                  {!token ? <div className="boxes-wrapper"> <div data-toggle='modal' data-target='.bd-example-modal-lg' onClick={() => openSignUpTab('v-pills-signup-tab', 1)} className="boxes investor">
                    <span data-toggle='modal' className="h3">Investors</span>
                    <span className="h5">GET IN THE GAME</span>
                  </div></div> : <div className="boxes-wrapper"><div data-toggle='modal' className="boxes investor">
                    <span data-toggle='modal' className="h3">Investors</span>
                  </div></div>}
                  <div className="points">
                    <ul>
                      <li>Support Your Favorite Artists</li>
                      <li>NFTs are BIG Business</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="timer-wrapper">
              <h4>Next Launch To The Moon</h4>
              <div>
                <FlipClock
                  type="countdown"
                  count_to={time} // 2023-01-01 00:00:00
                  units={[
                    {
                      sep: ':',
                      type: 'days',
                      title: '',
                    },
                    {
                      sep: ':',
                      type: 'hours',
                      title: '',
                    },
                    {
                      sep: ':',
                      type: 'minutes',
                      title: '',
                    },
                    {
                      sep: ':',
                      type: 'seconds',
                      title: '',
                    }
                  ]}
                />
              </div>
              {/* <div className="timer" id="timer">
                <span><span id="days">{day}</span><small>DAYS</small></span>
                <span><span id="hours">{hours}</span><small>HOURS</small></span>
                <span><span id="minutes">{minutes}</span><small>MINUTES</small></span>
                <span><span id="seconds">{seconds}</span><small>SECONDS</small></span>
              </div> */}
              <button className="btn-default hvr-bounce-in" onClick={() => openSubscribeModel()}><span className="icon">
                <img loading="lazy" className="img-fluid" src={props.Logo} alt="Nexus Logo" />
              </span>Subscribe Now</button>
            </div>
          </div>
        </div>
        <div className='modal fade cart' tabIndex={-1} role='dialog' id="subscribeModel" aria-labelledby='subscribeModel' aria-hidden='true' data-backdrop="static" data-keyboard="false">
          <div className='modal-dialog modal-md modal-dialog-centered'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>Subscription Model</h5>
                <button onClick={() => closeSubscribeModel()} type='button' className='close' data-dismiss='modal' aria-label='Close'>
                  <span aria-hidden='true'>×</span>
                </button>
              </div>
              <div className='modal-body'>
                <div className='form-group'>
                  <label>User Name</label>
                  <input type='text' className='form-control' placeholder='User Name'
                    name='name' value={state.name} onChange={(e) => handleChange(e)} />
                </div>
                <div className='form-group'>
                  <label>Email address</label>
                  <input type='email' className='form-control' placeholder='Enter email'
                    name='email' value={state.email} onChange={(e) => handleChange(e)} />
                </div>

                <br />
                <div className={state.errors ? 'alert alert-danger m-auto' : ''} role='alert' style={{ color: 'red', fontWeight: 'bold' }}>{state.errors}</div>
                {!state.loading ? <button onClick={() => subscribeHandler()} type='submit' className='btn-default hvr-bounce-in'>
                  Subscribe
                </button> : <SmallLoader />}
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
  )
}
const mapStateToProps = state => {
  return {
    launch_time: state.siteSettingReducer.launch_time,
    token: state.userReducer.token
  }
}

export default connect(mapStateToProps)(CreatorInvestor)