import Logo from './../../assets/img/svg/logo.svg';
// import AboutImage from './../../assets/img/about.png';
import ArtCommerce from './../../assets/img/art-commerce.svg';
import { withRouter } from 'react-router-dom';
import LeftArrow from './../../assets/img/left-arrow.png';
import RightArrow from './../../assets/img/right-arrow.png';
import GetStart from './../../assets/img/getting-started.png';
// import BlockChainImage from './../../assets/img/blockchain.png';
import ArtistImage from './../../assets/img/banner-1.png'
import MusicianImage from './../../assets/img/banner-2.png'
import InvestorImage from './../../assets/img/banner-3.png'

import React, { Suspense, useEffect, useState, Fragment } from 'react'
import ReactPlayer from 'react-player'
import Slider from "react-slick"
import Ecommerce from "./Ecommerce"
import CreatorInvestor from './CreatorInvestor'
import AboutAndWhy from './AboutAndWhy'
import GettingStarted from './GettingStarted'
import RangeCheck from './RangeCheck'
import Loader from '../loader/Loader';
import { VideoGuideAPi } from './../../container/Api/api'
import { connect } from 'react-redux';
import { selectedUserType } from './../../store/actions/userAction'
import { fetchBannerImagesData } from './../../store/actions/siteAction'
import { useMediaQuery } from 'react-responsive'
import { useLocation } from "react-router-dom";
import IntroVideo from './IntroVideo'

const MostWatchedSlide = React.lazy(() => import('./MostWatchedSlide'));
// const BannerSlide = React.lazy(() => import('./BannerSlide'));
const $ = window.jQuery

function HomePage(props) {
  $(window).scrollTop('0');

  function HomeNextArrow(props) {
    const { onClick } = props;
    return (
      <img loading="lazy" onClick={onClick} src={RightArrow} alt='right-arrow.png' className='img-fluid slick-arrow next' />
    );
  }

  function HomePrevArrow(props) {
    const { onClick } = props;
    return (
      <img loading="lazy" onClick={onClick} src={LeftArrow} alt='left-arrow.png' className='img-fluid slick-arrow prev' />
    );
  }

  const sliderSetting = {
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 200000,
    infinite: false,
    arrows: false,
    prevArrow: <HomePrevArrow />,
    nextArrow: <HomeNextArrow />,
    dots: false,
    pauseOnHover: true,
    responsive: [{
      breakpoint: 992,
      settings: {
        slidesToShow: 2
      }
    }, {
      breakpoint: 767,
      settings: {
        slidesToShow: 1
      }
    }]
  };

  function BannerNextArrow(props) {
    const { onClick } = props;
    return (
      <div onClick={onClick} className="sliderRightArrow"><img loading="lazy" onClick={onClick} src={RightArrow} alt='right-arrow.png' className='img-fluid slick-arrow next' /></div>
    );
  }

  function BannerPrevArrow(props) {
    const { onClick } = props;
    return (
      <div onClick={onClick} className="sliderLeftArrow"><img loading="lazy" onClick={onClick} src={LeftArrow} alt='left-arrow.png' className='img-fluid slick-arrow prev' /></div>
    );
  }
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 800px)' });
  const _settings = {
    initialSlide: isTabletOrMobile ? 1 : 0,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 200,
    arrows: (props.BannerImages.length > 3) ? true : false,
    prevArrow: <BannerPrevArrow />,
    nextArrow: <BannerNextArrow />,
    dots: true,
    pauseOnHover: true,
    infinite: true,
    responsive: [{
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
        initialSlide: 1
      }
    }, {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        initialSlide: 1
      }
    }]
  };

  const [videoGuideData, setVideoGuideData] = useState([])
  const [videoUrl, setVideoUrl] = useState('')
  const [isPlay, setIsPlay] = useState(false)
  const [ownToken, setOwnToken] = useState(localStorage.getItem('token'))

  const location = useLocation();

  useEffect(() => {
    // console.log("hi Saqib", location.search)
    // console.log(location.pathname); // result: '/secondpage'
    // console.log(location.search); // result: '?query=abc'
    if (location.search && location.search === '?query=OpenAuthModel') {
      $("#myLargeModalLabel").modal('show');
    }
  }, [location]);

  useEffect(() => {
    if (!props.fetched) {
      fetchVideoGuide()
      props.dispatch(fetchBannerImagesData())
    }
            // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchVideoGuide() {
    let res = await VideoGuideAPi();
    if (res.data.status === 0) {
      setVideoGuideData([])
    } else if (res.data.status === 1) {
      setVideoGuideData(res.data.data)
    }
  }

  useEffect(() => {
    setOwnToken(localStorage.getItem('token'))
  }, [props.token])

  const openSignUpTab = (param, value) => {
    // alert('its alert')
    // console.log("cliiiiiiiick", param, value)
    $(`#${param}`).tab('show');
    props.dispatch(selectedUserType(value))
    // console.log("aaafter Click")
  }

  const getThumbnailYoutube = (url, quality) => {
    if (url) {
      var video_id, thumbnail;
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/)) {
        video_id = url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/).pop();
      } else if (url.match(/youtu.be\/(.{11})/)) {
        video_id = url.match(/youtu.be\/(.{11})/).pop();
      }

      if (video_id) {
        if (typeof quality === "undefined") {
          quality = 'high';
        }
        var quality_key = 'maxresdefault'; // Max quality
        if (quality === 'low') {
          quality_key = 'sddefault';
        } else if (quality === 'medium') {
          quality_key = 'mqdefault';
        } else if (quality === 'high') {
          quality_key = 'hqdefault';
        }

        thumbnail = "http://img.youtube.com/vi/" + video_id + "/" + quality_key + ".jpg";
        return thumbnail;
      }
    }
    return false
  }

  const openVideoHandler = (url) => {
    setIsPlay(true)
    setVideoUrl(url)
    $(`#myYoutubeModal`).modal('show');
  }

  const isModalClose = () => {
    setIsPlay(false)
    setVideoUrl('')
    $(`#myYoutubeModal`).modal('hide');
  }
  const videoURL = props.homeVideo;

  return (
    <Suspense fallback={<Loader />}>
      {/* <BannerSlide /> */}
      <section className='banner-area'>
        {props.BannerImages.length > 0 ? <Slider {..._settings} className='artist-list' >
          {props.BannerImages.map((item, i) => {
            return <div className='artist' key={i}>
              <div data-toggle='modal' data-target={!ownToken ?'.bd-example-modal-lg' : ''} className='image' onClick={() => openSignUpTab('v-pills-signup-tab', i + 1)}>
                <img loading="lazy" src={item.bannerImage} alt='1' className='img-fluid' />
              </div>
              <div className='content'>
                <h2>{item.bannerTitle}</h2>
                {item.bannerSubTitle ? <h5>{item.bannerSubTitle}</h5> : ''}
                {item.bannerDescription ? <p>{item.bannerDescription}</p> : ''}
                {!ownToken ?
                  <button className='btn-default hvr-bounce-in'
                    data-toggle='modal' data-target='.bd-example-modal-lg' onClick={() => openSignUpTab('v-pills-signup-tab', i + 1)}>
                    <span className='icon'>
                      <img loading="lazy" className='img-fluid' src={Logo} alt='Nexus Logo' />
                    </span>GET IN THE GAME </button> : ''}
              </div>
            </div>
          })}
        </Slider> :
          <Slider  {..._settings} className='artist-list'>
            <div className='artist'>
              <div className='image'>
                <img loading="lazy" src={ArtistImage} alt='1' className='img-fluid' />
              </div>
              <div className='content'>
                <h2>Artist</h2>
                {!ownToken ?
                  <button className='btn-default hvr-bounce-in'
                    data-toggle='modal' data-target='.bd-example-modal-lg' onClick={() => openSignUpTab('v-pills-signup-tab', 2)}>
                    <span className='icon'>
                      <img loading="lazy" className='img-fluid' src={Logo} alt='Nexus Logo' />
                    </span>GET IN THE GAME  </button> : ''}
              </div>
            </div>
            <div className='artist'>
              <div className='image'>
                <img loading="lazy" src={InvestorImage} alt='1' className='img-fluid' />
              </div>
              <div className='content'>
                <h2>Investor</h2>
                {!ownToken ?
                  <button className='btn-default hvr-bounce-in'
                    data-toggle='modal' data-target='.bd-example-modal-lg' onClick={() => openSignUpTab('v-pills-signup-tab', 1)}>
                    <span className='icon'>
                      <img loading="lazy" className='img-fluid' src={Logo} alt='Nexus Logo' />
                    </span>GET IN THE GAME  </button> : ''}
              </div>
            </div>
            <div className='artist'>
              <div className='image'>
                <img loading="lazy" src={MusicianImage} alt='1' className='img-fluid' />
              </div>
              <div className='content'>
                <h2>Musician</h2>
                {!ownToken ?
                  <button className='btn-default hvr-bounce-in'
                    data-toggle='modal' data-target='.bd-example-modal-lg' onClick={() => openSignUpTab('v-pills-signup-tab', 3)}>
                    <span className='icon'>
                      <img loading="lazy" className='img-fluid' src={Logo} alt='Nexus Logo' />
                    </span>GET IN THE GAME  </button> : ''}
              </div>
            </div>

          </Slider>}

      </section>
      <Ecommerce Image={ArtCommerce} Logo={Logo} />

      <AboutAndWhy Image={GetStart} />
      <CreatorInvestor Logo={Logo} />
      <MostWatchedSlide />
      {/* <GettingStarted Logo={Logo} Image={GetStart} isAuthenticated={props.token} /> */}
      <RangeCheck Logo={Logo} />

      {videoGuideData && videoGuideData.length > 0 ? <section className="nexus-guide">
        <div className="container" id="videoGuides">
          <div className="section-heading">
            <h2>Nexus Video Guides</h2>
            <div className="slider-arrows">
              <button className="prev slider-arrow">
                <img loading="lazy" src={LeftArrow} alt="" className="img-fluid" />
              </button>
              <button className="next slider-arrow">
                <img loading="lazy" src={RightArrow} alt="" className="img-fluid" />
              </button>
            </div>
          </div>

          <Slider className="videos-slider" {...sliderSetting}>
            {videoGuideData && videoGuideData.length > 0 ? videoGuideData.map((item, i) => {
              return <Fragment key={i}>
                <div className="col-md-12" style={{ cursor: 'pointer' }}>
                  <div className="video">
                    <div className="image">
                      <img loading="lazy" onClick={() => openVideoHandler(item.url)} src={item.url ? getThumbnailYoutube(item.url, 'medium') : getThumbnailYoutube('http://img.youtube.com/vi/JV9x2Y2tawY/maxresdefault.jpg', 'medium')} alt={item.title} className="img-fluid" />
                    </div>
                  </div>
                  <div className="video-title">
                    <p>{item.title}</p>
                  </div>
                </div>
              </Fragment>
            }) : ''}
          </Slider>
        </div>
      </section> : ''}

      <section className="blockchain">
        <div className="container">
          <div className="row">
            <div className="col-md-7 d-flex align-items-center">
              <div className="content">
                <div className="h1-title">It's on MATIC!</div>
                <div className="points">
                  <ul>
                    <li><img draggable="false" role="img" class="emoji" alt="🎨" src="https://s.w.org/images/core/emoji/14.0.0/svg/1f3a8.svg"/> Sign Up & Mint your Art, Film, Gaming or Music NFTs </li>
                    <li> <img draggable="false" role="img" class="emoji" alt="🎉" src="https://s.w.org/images/core/emoji/14.0.0/svg/1f389.svg"/>FREE Minting. NO MORE CRAZY ETHEREUM FEES!</li>
                    <li><img draggable="false" role="img" class="emoji" alt="💰" src="https://s.w.org/images/core/emoji/14.0.0/svg/1f4b0.svg"/> Get in the Game and let others invest in your career </li>
                    <li><img draggable="false" role="img" class="emoji" alt="🌕" src="https://s.w.org/images/core/emoji/14.0.0/svg/1f315.svg"/> Invest in the NFTs with 1000X potential.</li>
                    <li><img draggable="false" role="img" class="emoji" alt="🔔" src="https://s.w.org/images/core/emoji/14.0.0/svg/1f514.svg"/>Subscribe and stay up to date with the latest Nexus NFT news</li>
                  </ul>
                </div>
                <button className="btn-default hvr-bounce-in" >
                  GET IN THE GAME </button>
              </div>
            </div>
            <div className="col-md-5">
              {/* <div className="image">
                <img loading="lazy" src={BlockChainImage} alt="" className="img-fluid" />
              </div> */}
              <IntroVideo video={videoURL} />
            </div>
          </div>
        </div>
      </section>
      <div className="modal fade" id="myYoutubeModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <button onClick={() => isModalClose()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="embed-responsive embed-responsive-16by9">
                <ReactPlayer playing={isPlay} controls={true} url={videoUrl} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Suspense>
  )
}
const mapStateToProps = state => {
  return {
    BannerImages: state.siteSettingReducer.bannerImages,
    token: state.userReducer.token,
    loading: state.userReducer.loading,
    homeVideo: state.siteSettingReducer.homeVideo,

  }
}

export default connect(mapStateToProps)(withRouter(HomePage));