import {useState, useEffect} from "react"
import StickyVideo from 'react-sticky-video';
import 'react-sticky-video/dist/index.css'

export default function IntroVideo({ video }) {
  const [autoPlay, setAutoplay] = useState(true)
  useEffect(() => {
    window.scrollTo({ top: 100 });
    setAutoplay(true)
    setTimeout(() => {
      window.scrollTo({ top: 100 });
      // setAutoplay(false)
    }, 5000)
  }, [])

  return (
    <div className="mb-5 video">
      <StickyVideo
        url={video && video !== undefined ? video : "https://www.youtube.com/watch?v=SkE6LUoSrRU"}
        playerVars={{
          autoplay: autoPlay,
          mute:true
        }}
        width={560}
        height={315}
        originalControls={true}
        stickyConfig={{
          width: 320,
          height: 315,
          position: 'top-right',
        }}
      />
    </div>
  )
}
