import { Component } from 'react'
import { loginValidation } from './Validation'
import { LoginApi } from './../../container/Api/api'
import { userUpdateToken } from '../../store/actions/userAction'
import { connect } from 'react-redux'
import GoogleLogin from 'react-google-login';
import { gapi } from 'gapi-script';
import { Message } from '../../utils/message'
import axios from 'axios'

import { OldSocialLogin as SocialLogin } from 'react-social-login'
import { withRouter } from 'react-router-dom'
const $ = window.jQuery
class SocialSignin extends Component {
  state = {
    email: '',
    password: '',
    isSubmitted: false,
    errors: '',
    account: '',
    loading: false
  }

  handleSocialLogin = e => {
    if (e._provider === 'google') {
      // e.preventDefault()
      const { firstName, lastName, email, name, id } = e._profile
      axios
        .post(`${process.env.REACT_APP_BASE_URL}api/social/register`, {
          firstName: firstName,
          lastName: lastName,
          type: 2,
          id: id,
          email: email,
          username: name
        })
        .then(res => {
          // console.log('successfully login', res)
          if (res.data.message !== '') {
            // console.log("res.data.data.id", res.data.data.user_id)
            // console.log("res.data.token", res.data.token)
            this.props.dispatch(userUpdateToken(res.data.token, res.data.data.user_id))
            //  const message = res.data.message
            //   this.setState({ message, error: '', loading: false })
            Message('success', 'Success', 'logged in')
            $('#myLargeModalLabel').hide()
            $('body').removeClass('modal-open')
            $('body').css('padding-right', '0px')
            $('.modal-backdrop').remove()
            this.props.history.push('/ad-manager')
          } else {
            const Error = res.data.message
            Message('error', 'Error', Error)
            this.setState({
              errors: Error ? Error : 'Invalid Email / Password ',
              loading: false
            })
          }
        })
        .catch(err => {
          console.log('error in api auth', err)
        })
    } else {
      console.log(e)
      e.preventDefault()
    }
  }

  handleSocialLoginFailure = err => {
    console.error("itss Failure ", err)
  }
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
      errors: ''
    })
  }
  validateForm = () => {
    var form = document.getElementsByClassName('needs-validation')[0]
    let isValid = true
    if (form.checkValidity() === false) {
      isValid = false
      form.classList.add('was-validated')
    }
    return isValid
  }
  handleSubmit = async e => {
    e.preventDefault()
    let isValid = this.validateForm()
    if (isValid) {
      this.setState({ isSubmitted: true, errors: undefined })
      const { isValid, errors } = loginValidation(this.state)
      if (!isValid) {
        this.setState({ errors, isSubmitted: false })
        return false
      } else {
        this.setState(state => {
          return { loading: !state.loading }
        })
        let body = { email: this.state.email, password: this.state.password }
        let res = await LoginApi(body)
        try {
          if (res.data.status === 1) {
            this.props.dispatch(
              userUpdateToken(res.data.token, res.data.data.user_id)
            )
            const message = res.data.message
            this.setState({ message, error: '', loading: false })
            Message('success', 'Success', message)
            $('#myLargeModalLabel').hide()
            $('body').removeClass('modal-open')
            $('body').css('padding-right', '0px')
            $('.modal-backdrop').remove()
          } else if (res.data.status === 0) {
            const Error = res.data.message
            Message('error', 'Error', Error)
            this.setState({
              errors: Error ? Error : 'Invalid Email / Password ',
              loading: false
            })
          }
        } catch (err) {
          console.error(err)
        }
      }
    }
  }
  componentDidMount() {
    //Its purely Related to Google OAuth 2.0 
    function start() {
      gapi.client.init({
        clientId: '864864661811-lh7p6uvqsh6cosdcqc2528vn0vfqbk54.apps.googleusercontent.com',
        scope: 'email',
        plugin_name: 'Nexus.art'

      })
    }
    gapi.load('client:auth2', start)


    this.handleMetamask()
  }

  handleMetamask = async e => {
    // e.preventDefault()

    const { ethereum } = window
    try {
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x89' }]
      })
    } catch (addError) {
      console.error('Error in switching network', addError)
    }

    if (typeof window.ethereum !== 'undefined') {
      // console.log('MetaMask is installed!')
      const accounts = await ethereum.request({ method: 'eth_requestAccounts' })
      const account = accounts[0]
      if (account !== '') {
        const first =
          accounts[0].slice(0, 3) + '...' + accounts[0].slice(39, 42)
        this.setState({
          account: first
        })
      }

      // console.log('accounts', accounts[0])
      ethereum.on('accountsChanged', accounts => {
        // Handle the new accounts, or lack thereof.
        // "accounts" will always be an array, but it can be empty.
        this.setState({
          account: accounts[0]
        })
        console.log('accounts', accounts[0])
      })
    }
  }

  handleVenlyBtn = e => {
    e.preventDefault()
    axios
      .post(
        'https://login.arkane.network/auth/realms/Arkane/protocol/openid-connect/token',
        {
          grant_type: 'client_credentials',
          client_id: 'Testaccount-capsule',
          client_secret: '82c19251-1753-44f5-ae76-93438d3628de'
        }
      )
      .then(res => {
        // console.log(res)
      })
      .catch(err => {
        console.log('Error in venly api call', err)
      })
  }
  responseFacebook = (e, err) => {
    // console.log(e._profile)

    // e.preventDefault()
    const { firstName, lastName, email, name, id } = e._profile

    axios
      .post(`${process.env.REACT_APP_BASE_URL}api/social/register`, {
        firstName: firstName,
        lastName: lastName,
        type: 1,
        email: email,
        id: id,
        username: name
      })
      .then(res => {
        // console.log('successfully login', res)
        if (res.data.message !== '') {
          console.log("e._token.accessToken, id", res.data.token, id,res.data.data.user_id)
          this.props.dispatch(userUpdateToken(res.data.token,res.data.data.user_id))
          //  const message = res.data.message
          //   this.setState({ message, error: '', loading: false })
          Message('success', 'Success', 'logged in')
          $('#myLargeModalLabel').hide()
          $('body').removeClass('modal-open')
          $('body').css('padding-right', '0px')
          $('.modal-backdrop').remove()
          this.props.history.push('/ad-manager')


        } else {
          const Error = res.data.message
          Message('error', 'Error', Error)
          this.setState({
            errors: Error ? Error : 'Invalid Email / Password ',
            loading: false
          })
        }
      })
      .catch(err => {
        console.log('error in api auth', err)
      })
    // console.log(err)
  }

  handleGooogleAuth2 = (e) => {
    if (e.profileObj) {
      // e.preventDefault()
      const { givenName, familyName, email, name, googleId } = e.profileObj

      axios
        .post(`${process.env.REACT_APP_BASE_URL}api/social/register`, {
          firstName: givenName,
          lastName: familyName,
          type: 2,
          id: googleId,
          email: email,
          username: name
        })
        .then(res => {
          // console.log('successfully login', res)
          if (res.data.message !== '') {
            console.log("res.data.data.user_id",res.data.data)
            // console.log("res.data.data.id", res.data.data.user_id)
            // console.log("res.data.token", res.data.token)
            this.props.dispatch(userUpdateToken(res.data.token, res.data.data.user_id))
            //  const message = res.data.message
            //   this.setState({ message, error: '', loading: false })
            Message('success', 'Success', 'logged in')
            $('#myLargeModalLabel').hide()
            $('body').removeClass('modal-open')
            $('body').css('padding-right', '0px')
            $('.modal-backdrop').remove()
            this.props.history.push('/ad-manager')
          } else {
            const Error = res.data.message
            Message('error', 'Error', Error)
            this.setState({
              errors: Error ? Error : 'Invalid Email / Password ',
              loading: false
            })
          }
        })
        .catch(err => {
          console.log('error in api auth', err)
        })
    }
  }
  render() {
    const { NexusLogo } = this.props
    const {errors } = this.state
    return (
      <div
        // className='tab-pane fade show active'
        // id='v-pills-login'
        // role='tabpanel'
        // aria-labelledby='v-pills-login-tab'
      >
        <div className='form-wrapper'>
          <div className='logo'>
            <img src={NexusLogo} alt='' className='img-fluid' />
          </div>
          {/* <h4>Get In the Game</h4> */}
          <form className='needs-validation' noValidate>
            <div className='form-group'>
              <div className="social-buttons">
                {/* {this.state.account === '' ? (
                  <button
                    onClick={this.handleMetamask}
                    className=' btn-default hvr-bounce-in'
                  >
                    Connect to Metamask
                  </button>
                ) : (
                  <button
                    onClick={this.handleMetamask}
                    className=' btn-default hvr-bounce-in'
                  >
                    {this.state.account}
                  </button>
                )} */}
              
              {/* {  its oAuth1.0 } doing issue with new clientId but perfect with previous one */}
              {/* <SocialButton
                type='button'
                provider='google'
                // appId='85583281265-1a2q3oaj5vhmb6k7udesi7ejmao94ts7.apps.googleusercontent.com' //orginal
                className=' btn-default hvr-bounce-in'
                onLoginSuccess={this.handleSocialLogin}
                onLoginFailure={this.handleSocialLoginFailure}
              >
                Login with Google
              </SocialButton> */}

              {/* {  its updated oAuth2.0 } */}

              <GoogleLogin
                clientId="864864661811-lh7p6uvqsh6cosdcqc2528vn0vfqbk54.apps.googleusercontent.com"
                buttonText="Login With Google"
                onSuccess={this.handleGooogleAuth2}
                onFailure={this.handleSocialLoginFailure}
                className=' btn-primary hvr-bounce-in'
              // cookiePolicy={'single_host_origin'}
              // isSignedIn={true}

              />

              {/* <meta name="google-signin-client_id" content="864864661811-lh7p6uvqsh6cosdcqc2528vn0vfqbk54.apps.googleusercontent.com"></meta>
              <div class="g-signin2" data-onsuccess={"handleCredentialResponse"}></div> */}

              {/* <button
                className='btn-default hvr-bounce-in'
                onClick={this.handleVenlyBtn}
              >
                Login With Venly{' '}
              </button> */}
              {/* <FacebookLogin
                appId='1041654396386719'
                autoLoad={true}
                fields='name,email,picture'
           
                callback={this.responseFacebook}
                render={renderProps => (
                  <button onClick={renderProps.onClick}>This is my custom FB button</button>
                )}
              /> */}
              <SocialLogin
                type='button'
                provider='facebook'
                appId='521817933023856'
                // appId='422965656011369'
                scope="public_profile,email"
                // version='v13.0'
                callback={this.responseFacebook}
              >
                <button
                  className='btn-primary hvr-bounce-in'
                  onClick={this.handleSocialLogin}
                >
                  Login with Facebook
                </button>
              </SocialLogin>
              </div>
            </div>
            <br />
            <div
              className={errors ? 'alert alert-danger m-auto' : ''}
              role='alert'
              style={{ color: 'red', fontWeight: 'bold' }}
            >
              {errors}
            </div>
          </form>
        </div>
      </div>
    )
  }
}
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(mapDispatchToProps)(withRouter(SocialSignin))
